.Content {
  text-align: center;
  margin-left: 300px;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

td, th {
  text-align: left !important;
  width:0.1%;
  white-space: nowrap;  
}

td.left, th.left {
  text-align: left !important;
}

td.right, th.right {
  text-align: right !important;
}

td.center, th.center {
  text-align: center !important;
}

.plain-list {
  list-style-type: none;
  padding-left: 0px;
}

.sidebar {
  font-family: sans-serif;
  text-align: center;
}

div.MuiToolbar-root {
  display: none !important;
}

div.MuiDrawer-paper {
  top: 56px;
}

span.app-title {
  font-weight: bold;
  color: white;
  font-size: larger;
}

div.content-toolbar {
  text-align: left;
  display: flex;
  flex-direction: row;
}

.content-toolbar > div,
.content-toolbar > button {
  margin-right: 10px;
}

td div.dropdown.tableRowAction button {
  border: 0 !important;
  background-color: transparent !important;
  color: black;
}

td div.dropdown.tableRowAction button::after {
  margin: 0;
  border: 0;
}

td div.dropdown.tableRowAction button svg.bi-three-dots-vertical {
  scale: 1.2;
  vertical-align: baseline;
}

div.modal-footer div.spinner-border {
  margin-right: auto;
}

div.rct-tree-root {
  margin-left: -36px;
}

li.rct-tree-item-li {
  font-size: medium;
}

/* react-complex-tree */
:root {
  --rct-item-height: 36px !important;
}

div.container-fluid.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

div.spinner-border.xlarge {
  width: 6rem;
  height: 6rem;
}