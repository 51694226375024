body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

nav#mainNav {
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px;
}

div.userControl {
    display: flex;
    align-items: center;
}

.centeredBody {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #eee, #aaa);
}

.centeredIcon {
    text-align: center;
    margin: 16px;
}

@keyframes ellipsis {
    to {
        width: 1.2em;
        margin-right: 0em;
    }
}

.loadingEllipsis:after {
    margin-right: 1.2em;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0em;
}




/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

.vertical-nav {
    min-width: 17rem;
    width: 17rem;
    height: 100vh;
    position: fixed;
    top: auto;
    left: 0;
    padding-top: 20px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.4s;
  }
  
  .page-content {
    width: calc(100% - 17rem);
    margin-left: 17rem;
    transition: all 0.4s;
  }

  .page-content h4 {
    margin-bottom: 18px;
  }
  
  /* for toggle behavior */
  
  #sidebar.active {
    margin-left: -17rem;
  }
  
  #content.active {
    width: 100%;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    #sidebar {
      margin-left: -17rem;
    }
    #sidebar.active {
      margin-left: 0;
    }
    #content {
      width: 100%;
      margin: 0;
    }
    #content.active {
      margin-left: 17rem;
      width: calc(100% - 17rem);
    }
  }
  
  /*
  *
  * ==========================================
  * FOR DEMO PURPOSE
  * ==========================================
  *
  */
/*   
  body {
    background: #599fd9;
    background: -webkit-linear-gradient(to right, #599fd9, #c2e59c);
    background: linear-gradient(to right, #599fd9, #c2e59c);
    min-height: 100vh;
    overflow-x: hidden;
  } */
  
  .separator {
    margin: 3rem 0;
    border-bottom: 1px dashed #fff;
  }
  
  .text-uppercase {
    letter-spacing: 0.1em;
  }
  
  .text-gray {
    color: #aaa;
  }
  